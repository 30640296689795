export function find() {
  return {
    code: "OK",
    message: "La operación se completó exitosamente.",
    data: {
      id: 1,
      name: "Motorcity",
      order: 0,
      active: true,
      steps: [
        // {
        //   id: 0,
        //   name: "2. Datos Generales",
        //   details: "Contesta el cuestionario y haz clic en Siguiente",
        //   order: 2,
        //   step: 2,
        //   maxAnswers: 1,
        //   required: true,
        //   answers: [
        //     {
        //       id: 0,
        //       name: "¿En qué estado vives?",
        //       key: "stateId",
        //       type: "string",
        //       component: "dropdown",
        //       catalog: "state",
        //     },
        //   ],
        // },
        // {
        //   id: 0,
        //   name: "",
        //   details: "",
        //   order: 2,
        //   step: 2,
        //   maxAnswers: 1,
        //   required: true,
        //   answers: [
        //     {
        //       id: 0,
        //       name: "¿Cuál es tu estatus laboral actualmente?",
        //       key: "professionId",
        //       type: "string",
        //       component: "dropdown",
        //       catalog: "profession",
        //     },
        //   ],
        // },

        {
          id: 0,
          name: "2. Información Financiera",
          details: "Contesta el cuestionario y haz clic en Siguiente",
          order: 1,
          step: 2,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 0,
              name: "¿Cuál es tu ingreso mensual?",
              key: "incomeId",
              type: "string",
              component: "dropdown",
              catalog: "income",
            },
          ],
        },
        {
          id: 0,
          name: "",
          details: "",
          order: 2,
          step: 2,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 0,
              name: "¿Cómo te gustaría pagar tu auto?",
              key: "vehiclePaymentMethod",
              type: "string",
              component: "dropdown",
              catalog: "vehiclePaymentMethod",
            },
          ],
        },
        {
          id: 0,
          name: "",
          details: "",
          order: 3,
          step: 2,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 0,
              name: "¿Cuál es el presupuesto total para tu auto nuevo?",
              key: "maxPriceId",
              type: "string",
              component: "dropdown",
              catalog: "maxPrice",
            },
          ],
        },
      ],
    },
  };
}
export function find_Register() {
  return {
    code: "OK",
    message: "La operación se completó exitosamente.",
    data: {
      id: 1,
      name: "Motorcity",
      order: 0,
      active: true,
      steps: [
        {
          id: 0,
          name: "1. Datos generales",
          details: "",
          order: 1,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            // {
            //   id: 0,
            //   name: "Nombre",
            //   key: "name",
            //   type: "string",
            //   component: "text",
            // },
            {
              id: 1,
              name: "Teléfono",
              key: "phone",
              type: "string",
              order: 2,
              required: true,
              component: "phone",
            },
            {
              id: 2,
              name: "Correo electrónico",
              key: "email",
              order: 3,
              type: "string",
              component: "email",
              required: true,
            },
            // {
            //   id: 4,
            //   name: "Fecha de nacimiento",
            //   key: "birthdate",
            //   order: 5,
            //   type: "date",
            //   component: "date",
            //   required: true,
            // },
            {
              id: 5,
              name: "Acepto los términos y condiciones del servicio",
              key: "termsAndConditions",
              type: "boolean",
              order: 6,
              required: true,
              component: "switch",
            },
            // {
            //   id: 6,
            //   name: "Acepto el aviso de privacidad",
            //   key: "privacyNotice",
            //   type: "boolean",
            //   order: 7,
            //   required: true,
            //   component: "switch",
            // },
          ],
        },
      ],
    },
  };
}
