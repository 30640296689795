
import _, { any } from "underscore";
import { defineComponent, reactive, ref } from "vue";
import Dropdown from "primevue/dropdown";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
//import Catalog from "@/models/Catalog";
import * as CatalogService from "../api/helpers/Catalog";

export default defineComponent({
  name: "TypeDropDown",
  components: {
    Dropdown,
  },
  props: {
    validationLabel: String,
    isRequired: Boolean,
    isSubmitted: Boolean,
    //catalog: Catalog,
    catalog: String,
    answerKey: String,
    label: String,
  },
  setup(props) {
    var loading = ref(true);
    var catalogData = ref({});
    /* const getCatalog = async () => {
      catalogData.value = props.catalog?.data || [];
      loading.value = false;
    }; */
    const getCatalog = async () => {
      if (props.catalog == "state")
        await CatalogService._find(
          `/${props.catalog}?system=${process.env.VUE_APP_SYSTEM_ID}&order=name`
        ).then((res) => {
          catalogData.value = res.data.data;
          loading.value = false;
        });
      else if (props.catalog == "gender")
        await CatalogService._find(`/${props.catalog}?order=id:asc`).then(
          (res) => {
            catalogData.value = _.map(res.data.data, function(data: any) {
              return { id: data.id, name: data.description };
            });
            loading.value = false;
          }
        );
      else if (props.catalog === "vehiclePaymentMethod") {
        catalogData.value = [
          { id: "A crédito", name: "A crédito" },
          { id: "De contado", name: "De contado" },
        ];
        loading.value = false;
      } else
        await CatalogService._find(
          `/${props.catalog}?system=${process.env.VUE_APP_SYSTEM_ID}&order=name`
        ).then((res) => {
          catalogData.value = res.data.data;
          loading.value = false;
        });
    };
    const f = reactive({
      name: undefined,
    });

    var rules;
    rules = props.isRequired
      ? (rules = {
          name: { required },
        })
      : (rules = {
          name: {},
        });

    const v$ = useVuelidate(rules, f);

    return { f, v$, catalogData, getCatalog, loading };
  },
  mounted() {
    this.setValues();
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      typeof step === "object" &&
      this.answerKey &&
      Object.prototype.hasOwnProperty.call(step, this.answerKey)
    ) {
      this.v$.name.$model = step[this.answerKey];
      this.setValues();
    }
    this.getCatalog();
  },
  methods: {
    setValues() {
      this.$emit("getValue", {
        value: this.v$.name.$model,
        valid: !(!this.v$.name.$model && this.isRequired),
        answerKey: this.answerKey,
      });
    },
  },
});
